.image-gallery {
  margin: 3rem 0;
  .gallery-image {
    padding-bottom: 3rem;
    h6 {
      position: relative;
      margin-top: -46px;
      margin-left: 20px;
      span {
        padding: 0.5rem 1.5rem;
        border-top-right-radius: 12.5px;
        border-bottom-left-radius: 12.5px;
        color: $blue;
        background: $white;
      }
    }
  }
}
