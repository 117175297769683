#flow {
  max-width: 1920px;
  margin: 0 auto;
  margin-top: -180px;
  overflow: hidden;
  @media screen and (max-width: $large-tablet) {
    margin-top: 0;
  }
  .mobile-flow {
    display: none;
    @media screen and (max-width: $large-tablet) {
      display: inline;
      width: $large-tablet !important;
      background-size: cover;
      background-attachment: scroll !important;
    }
    @media screen and (max-width: $medium-tablet) {
      .flow-section {
        width: $medium-tablet !important;
        background-size: cover;
        background-attachment: scroll !important;
      }
    }
    @media screen and (max-width: $large-mobile) {
      .flow-section {
        width: $large-mobile !important;
        background-size: cover;
        background-attachment: scroll !important;
      }
    }
    @media screen and (max-width: $medium-mobile) {
      .flow-section {
        width: $medium-mobile !important;
        background-size: cover;
        background-attachment: scroll !important;
      }
    }
  }
  .flow-row {
    .flow-section {
      border-radius: 0;
      background-size: cover;
      background-attachment: fixed;
      background-blend-mode: darken;
      background-repeat: no-repeat;
      color: $orange;
      padding-top: 180px;
      height: 100vh;
      -webkit-transition: width 1s;
      -o-transition: width 1s;
      transition: width 1s;
      width: 25vw;
      .flow-content {
        margin-top: 6rem;
        h4 {
          span {
            -webkit-filter: drop-shadow(8px 8px 7px rgba(0, 0, 0, 0.7));
            filter: drop-shadow(8px 8px 7px rgba(0, 0, 0, 0.7));
          }
        }
        @media screen and (max-width: $medium-tablet) {
          margin-top: 0rem;
        }
      }
      @media screen and (max-width: $large-tablet) {
        padding-top: 74px;
      }
      @media screen and (max-width: $large-mobile) {
        padding-top: 50px;
      }
      p {
        -webkit-transition: opacity 1s;
        -o-transition: opacity 1s;
        transition: opacity 1s;
        opacity: 0;
        @media screen and (max-width: $large-tablet) {
          display: none;
        }
      }
      a {
        -webkit-transition: opacity 1s;
        -o-transition: opacity 1s;
        transition: opacity 1s;
        opacity: 0;
        background: $orange;
        color: $blue;
        font-weight: 600;
        @media screen and (max-width: $large-tablet) {
          display: none;
        }
      }
      &:hover {
        -webkit-transition: 1s;
        -o-transition: 1s;
        transition: 1s;
        width: 50vw;
        cursor: pointer;
        h4 {
          span {
            -webkit-transition: border 0.5s;
            -o-transition: border 0.5s;
            transition: border 0.5s;
            border-bottom: 4px solid $orange;
          }
        }
        p {
          -webkit-transition: opacity 3s;
          -o-transition: opacity 3s;
          transition: opacity 3s;
          opacity: 1;
        }
        a {
          -webkit-transition: opacity 3s;
          -o-transition: opacity 3s;
          transition: opacity 3s;
          opacity: 1;
        }
      }
      .flow-content {
        text-align: center;
        color: $white;
      }
      @media screen and (min-width: 1921px) {
        // background-size: 33.7%;
      }
      @media screen and (max-width: $large-tablet) {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        width: $large-tablet;
        height: 25vh;
        &:hover {
          width: $large-tablet;
        }
      }
    }
  }
}
