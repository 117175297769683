// Colors

$black: rgb(0, 0, 0);
$white: rgb(255, 255, 255);
$red: rgb(186, 45, 17);
$blue: rgb(20, 46, 92);
$slate: rgb(31, 42, 68);
$orange: rgb(237, 139, 0);
$green: rgb(151, 215, 0);
$yellow: rgb(242, 199, 92);
$teal: rgb(33, 178, 210);
$gray: rgb(218, 219, 220);
$lightGrey: rgb(163, 199, 234);
$darkGray: rgb(42, 54, 82);
$purple: rgb(102, 4, 95);
$lightBlue: rgb(155, 203, 235);
$gold: rgb(242, 199, 92);

// Media Queries

$medium-mobile: 375px;
$large-mobile: 414px;
$medium-tablet: 768px;
$large-tablet: 1024px;
