.slick-dots {
  margin-top: -50px;
  text-align: center;
  font-size: 1rem;
  li {
    position: relative;
    display: inline;
    button {
      font-size: 0rem;
      border: none;
      border-radius: 50%;
      background: $white;
      color: $white;
      margin-right: 1rem;
      height: 14px;
      width: 14px;
      &:hover {
        cursor: pointer;
      }
    }
  }
  .slick-active {
    button {
      -webkit-transition: 0.3s;
      -o-transition: 0.3s;
      transition: 0.3s;
      background: $orange;
      color: $orange;
    }
  }
}
