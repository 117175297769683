.contact-form {
  border-radius: 0 12.5px;
  .btn-large {
    padding: 0 4rem;
    background: $orange;
    color: $blue;
    font-weight: 600;
  }
  fieldset {
    border: none;
  }
  .frm_error_style {
    border-radius: 0 12.5px;
    background: $red;
    color: $white;
    padding: 1rem;
  }
  .frm_message {
    border-radius: 0 12.5px;
    background: $blue;
    color: $white;
    padding: 1rem;
    p {
      margin: 0;
      padding: 0;
    }
  }
  .frm_error {
    color: $red;
  }
  .frm_verify {
    display: none;
  }
  .frm_forms {
    padding-top: 1rem;
  }
}

input[type="text"]:not(.browser-default):focus:not([readyonly]) {
  border-bottom: 1px solid $blue;
  -webkit-box-shadow: 0 1px 0 0 $blue;
  box-shadow: 0 1px 0 0 $blue;
}

input[type="tel"]:not(.browser-default):focus:not([readyonly]) {
  border-bottom: 1px solid $blue;
  -webkit-box-shadow: 0 1px 0 0 $blue;
  box-shadow: 0 1px 0 0 $blue;
}

textarea:not(.browser-default):focus:not([readyonly]) {
  border-bottom: 1px solid $blue;
  -webkit-box-shadow: 0 1px 0 0 $blue;
  box-shadow: 0 1px 0 0 $blue;
}

.map-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
  border: 6px solid $orange;
  border-radius: 0 12.5px;
  iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }
}

.grecaptcha-badge {
  z-index: 9999;
}
