#hero {
  overflow: hidden;
  max-width: 1920px;
  margin: 0 auto;
  .hero-block {
    max-width: 1920px;
    margin: 0 auto;
    background-repeat: no-repeat;
    background-size: cover;
    background-blend-mode: darken;
    background-attachment: fixed;
    background-position: center;
    width: 100vw;
    height: 90vh;
    overflow: hidden;
    -webkit-clip-path: ellipse(85% 100% at center top);
    clip-path: ellipse(85% 100% at center top);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    color: $white;
    font-size: 6rem;
    @media screen and (max-width: $large-tablet) {
      background-attachment: scroll;
      -webkit-clip-path: ellipse(92% 100% at center top);
      clip-path: none;
      width: $large-tablet;
      height: 500px;
    }
    @media screen and (max-width: $medium-tablet) {
      width: $medium-tablet;
      height: 500px;
    }
    @media screen and (max-width: $large-mobile) {
      -webkit-clip-path: none;
      clip-path: none;
      width: $large-mobile;
      height: 500px;
    }
    @media screen and (max-width: $medium-mobile) {
      width: $medium-mobile;
      height: 500px;
    }
    video {
      z-index: -1;
    }
    a {
      i {
        position: absolute;
        margin-top: 28rem;
        font-size: 4rem;
        color: $white !important;
      }
    }
    .hero-text {
      position: absolute;
      text-align: center;
      padding: 1rem 0.5rem;
      @media screen and (max-width: $medium-tablet) {
        margin-top: 2rem;
      }
      h1 {
        padding: 0;
        margin: 0;
        -webkit-filter: drop-shadow(8px 8px 7px rgba(0, 0, 0, 0.7));
        filter: drop-shadow(8px 8px 7px rgba(0, 0, 0, 0.7));
        @media screen and (max-width: $large-tablet) {
          font-size: 3.5rem;
        }
        @media screen and (max-width: $large-mobile) {
          font-size: 3rem;
        }
      }
      h4 {
        -webkit-filter: drop-shadow(8px 8px 7px rgba(0, 0, 0, 0.7));
        filter: drop-shadow(8px 8px 7px rgba(0, 0, 0, 0.7));
        text-align: center;
        position: absolute;
        @media screen and (max-width: $large-mobile) {
          font-size: 2rem;
        }
        @media screen and (max-width: $medium-mobile) {
          font-size: 1.5rem;
        }
      }
      .btn-large {
        z-index: 9999;
        margin-top: 10rem;
      }
    }
  }
  .hero-block-image {
    max-width: 1920px;
    margin: 0 auto;
    background-repeat: no-repeat;
    background-size: cover;
    background-blend-mode: darken;
    background-attachment: fixed;
    background-position: center;
    width: 100vw;
    height: 50vh;
    overflow: hidden;
    -webkit-clip-path: ellipse(85% 100% at center top);
    clip-path: ellipse(85% 100% at center top);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    color: $white;
    font-size: 6rem;
    h1 {
      text-align: center;
      -webkit-filter: drop-shadow(8px 8px 7px rgba(0, 0, 0, 0.7));
      filter: drop-shadow(8px 8px 7px rgba(0, 0, 0, 0.7));
      @media screen and (max-width: $large-mobile) {
        font-size: 3rem;
        padding: 1rem;
      }
    }
    @media screen and (min-width: 1921px) {
      background-size: 33.7%;
    }
    @media screen and (max-width: $large-tablet) {
      background-attachment: scroll;
      width: $large-tablet;
      -webkit-filter: brightness(100%);
      filter: brightness(100%);
    }
    @media screen and (max-width: $medium-tablet) {
      background-attachment: scroll;
      width: $medium-tablet;
      -webkit-clip-path: ellipse(92% 100% at center top);
      clip-path: ellipse(92% 100% at center top);
      -webkit-filter: brightness(100%);
      filter: brightness(100%);
    }
    @media screen and (max-width: $large-mobile) {
      background-attachment: scroll;
      width: $large-mobile;
      -webkit-clip-path: ellipse(130% 100% at center top);
      clip-path: ellipse(130% 100% at center top);
      -webkit-filter: brightness(100%);
      filter: brightness(100%);
    }
    @media screen and (max-width: $medium-mobile) {
      background-attachment: scroll;
      width: $medium-mobile;
      -webkit-filter: brightness(100%);
      filter: brightness(100%);
    }
  }
  .hero-slider {
    position: absolute;
    top: 0;
    max-width: 100%;
    .hero-slide {
      height: 500px;
      color: $white;
      padding-top: 10rem;
    }
  }
}

.mobile-hero {
  display: none;
}
