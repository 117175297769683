.member-stories {
  .card {
    border: 0;
    border-top-right-radius: 25px;
    border-bottom-left-radius: 25px;
    .card-content {
      padding: 1rem;
      .btn-small {
        margin-top: 1rem;
        background: $orange;
        color: $blue;
      }
    }
  }
}
