#access {
  .card {
    border: none;
    border-radius: 0;
    border-top-right-radius: 25px;
    border-bottom-left-radius: 25px;
    color: $black;
    .card-title {
      color: $blue;
    }
  }
}

#support,
#connect {
  .card {
    border: none;
    border-radius: 0 12.5px;
    color: $black;
    .card-title {
      color: $blue;
    }
  }
}
