.cta-box {
  .cta-number {
    font-size: 4rem;
    color: $orange;
    padding-right: 0.5rem;
    border-right: 2px solid $lightBlue;
  }
  p {
    font-size: 1.2rem;
    color: $blue;
  }
}
