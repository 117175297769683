.fixed-action-btn {
  a {
    background: $orange !important;
  }
  i {
    color: $blue !important;
  }
  h5 {
    color: $blue;
    margin: 1rem;
    padding: 0;
  }
  .btn-large {
    font-weight: 600;
    color: $blue;
  }
  .form-floating {
    cursor: initial;
    border-top-right-radius: 25px;
    border-bottom-left-radius: 25px;
    border-top-left-radius: 0;
    border-bottom-right-radius: 0;
    position: absolute;
    bottom: 0;
    right: 0;
    background: $white;
    width: 300px;
    height: 560px;
  }
}
