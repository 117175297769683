// Chrome

::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  background: $blue;
}
::-webkit-scrollbar-thumb {
  background: $orange;
}
::-webkit-scrollbar-thumb:hover {
  background: $orange;
}

// Firefox

:root {
  scrollbar-color: $orange $blue !important;
  scrollbar-width: thin !important;
}
