html {
  scroll-behavior: smooth;
}

body {
  height: 100%;
  width: 100%;
  margin-bottom: -20px;
  padding: 0;
  border-left: 1px solid $slate;
  border-right: 1px solid $slate;
  -webkit-box-shadow: 0 0 19px rgb(31, 42, 68 / 32%), 0 0 80px transparent;
  box-shadow: 0 0 19px rgb(31, 42, 68 / 32%), 0 0 80px transparent;
  font-family: Trebuchet MS, Lucida Grande, Lucida Sans Unicode, Lucida Sans,
    Tahoma, sans-serif;
  opacity: 1;
  -webkit-transition-duration: 0.7s;
  -o-transition-duration: 0.7s;
  transition-duration: 0.7s;
  -webkit-transition-property: opacity;
  -o-transition-property: opacity;
  transition-property: opacity;
  max-width: 1920px;
  margin: 0 auto;
}

.fade {
  opacity: 0;
}

p {
  font-size: 1.09rem;
}

a {
  color: $blue;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  &:hover {
    color: $orange;
  }
}

.btn,
.btn-small {
  background: $orange;
  font-weight: 600;
  color: $blue;
  border: none;
  border-top-right-radius: 12.5px;
  border-bottom-left-radius: 12.5px;
  &:hover {
    background: $orange;
    color: $blue;
  }
}
.btn-large {
  background: $orange;
  font-weight: 600;
  color: $blue;
  border: none;
  border-top-right-radius: 12.5px;
  border-bottom-left-radius: 12.5px;
  &:hover {
    background: $orange;
    color: $blue;
  }
}

.container {
  -webkit-transition: 1s;
  -o-transition: 1s;
  transition: 1s;
  @media screen and (max-width: $large-tablet) {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
}

.container-full-width {
  -webkit-transition: 1s;
  -o-transition: 1s;
  transition: 1s;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.rtr {
  border-radius: 0;
  border-top-right-radius: 25px;
}

.rbl {
  border-radius: 0;
  border-bottom-left-radius: 25px;
}

.section-white {
  overflow: hidden;
  position: relative;
  background: $white;
  h1,
  h2,
  h3,
  h4 {
    color: $blue;
  }
}

.section-blue {
  overflow: hidden;
  position: relative;
  background: $blue;
  color: $white;
  .testimonial {
    blockquote {
      border-left: 5px solid $orange;
    }
    p {
      color: $white;
    }
  }
  .section-blue-list {
    li {
      margin-bottom: 1rem;
      i {
        color: $orange;
        margin-right: 5px;
      }
      a {
        color: $orange !important;
        &:hover {
          color: $white !important;
        }
      }
    }
  }

  h3 {
    color: $orange;
  }
  h1,
  h2,
  h4 {
    color: $white;
  }
  .cutout-bottom {
    width: 100%;
    height: 53px;
    position: absolute;
    bottom: -2px;
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
    &:before {
      content: "";
      position: absolute;
      left: -145px;
      width: 50%;
      height: 53px;
      top: 0;
      background-color: #fff;
      -webkit-transform: skew(-80deg);
      -ms-transform: skew(-80deg);
      transform: skew(-80deg);
    }
    &:after {
      content: "";
      position: absolute;
      right: -145px;
      width: 50%;
      height: 53px;
      top: 0;
      background-color: #fff;
      -webkit-transform: skew(80deg);
      -ms-transform: skew(80deg);
      transform: skew(80deg);
    }
  }
  .cutout-top {
    width: 100%;
    height: 53px;
    position: absolute;
    top: -2px;
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
    &:before {
      content: "";
      position: absolute;
      left: -145px;
      width: 50%;
      height: 53px;
      top: 0;
      background-color: #fff;
      -webkit-transform: skew(80deg);
      -ms-transform: skew(80deg);
      transform: skew(80deg);
    }
    &:after {
      content: "";
      position: absolute;
      right: -145px;
      width: 50%;
      height: 53px;
      top: 0;
      background-color: #fff;
      -webkit-transform: skew(-80deg);
      -ms-transform: skew(-80deg);
      transform: skew(-80deg);
    }
  }
}

#membership,
#training,
#members,
#help-center,
#contact,
#fourohfour,
#benefits,
#guide,
#connect {
  padding: 3rem 0;
}

.input-field {
  label {
    color: $blue !important;
  }
}

.btn-group {
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  vertical-align: middle;
  margin-bottom: 2rem;
}

.btn-group > .btn:first-child:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:not(:first-child):not(:last-child) {
  border-radius: 0;
}

.btn-group > .btn:last-child:not(:first-child),
.btn-group > .dropdown-toggle:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group > .btn-inactive {
  background-color: #607d8b;
}

.btn-group > .btn {
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  margin-top: 2rem;
  margin-right: 2px;
  line-height: 60px;
  height: 60px;
  font-weight: 600;
  background: $orange;
  color: $blue;
  -webkit-box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0),
    0 0px 0px 0px rgba(0, 0, 0, 0), 0 0px 0px 0 rgba(0, 0, 0, 0);
  box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0), 0 0px 0px 0px rgba(0, 0, 0, 0),
    0 0px 0px 0 rgba(0, 0, 0, 0);
  &:hover {
    background: $white;
  }
}

.btn-group > .btn-inactive:hover {
  background-color: #728f9d;
}

.btn-group > .btn:hover {
  -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}

.guide-list {
  li {
    padding-bottom: 2rem;
    font-size: 1.3rem;
    i {
      padding-right: 1rem;
      color: $orange;
    }
  }
}

.circle {
  background: $orange;
  color: $blue;
  width: 50px;
  height: 50px;
  margin: 0 auto;
  span {
    font-weight: 600;
    font-size: 1.3rem;
    line-height: 48px;
  }
}

.connect {
  border: 0;
  border-top-right-radius: 25px;
  border-bottom-left-radius: 25px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  i {
    font-size: 3rem;
    color: $blue;
  }
}

.fixed {
  position: fixed;
}

.row-mobile {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  @media screen and (max-width: $large-mobile) {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

.row-tablet {
  @media screen and (max-width: $large-tablet) {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 130px;
  @media screen and (max-width: $large-tablet) {
    position: initial;
    top: 0;
  }
}

.show-on-med-and-down {
  display: none;
  @media screen and (max-width: $large-tablet) {
    display: inline;
  }
}

.hide-on-large-and-down {
  @media screen and (max-width: $large-tablet) {
    display: none;
  }
}

.video-border {
  padding: 0.5rem;
  background-color: $orange;
  border-radius: 0 12.5px;
}

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}

.video-container iframe,
.video-container object,
.video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  .col.l4 {
    margin-left: initial;
  }
}
