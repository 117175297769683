.story-card {
  border-radius: 0 25px;
  padding: 1rem;
  .btn-large {
    background: $orange;
    color: $blue;
    font-weight: 600;
  }
  .card-title {
    color: $blue;
  }
  .quote-title {
    margin: 1rem 0;
    h6 {
      color: $blue;
      margin: 0;
      padding: 0;
    }
    p {
      margin: 0;
      padding: 0;
    }
  }
}
