#blog {
  margin-top: 2rem;
}

.blog-post {
  padding-bottom: 2rem;
  img {
    margin-bottom: -60px;
  }
  .card {
    border-radius: 0 12.5px;
    margin: 0 1rem;
    .card-date {
      margin: 10px 0;
    }
    .card-header {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      color: $blue;
      .card-title {
        margin-bottom: 0;
      }
    }
    .categories {
      margin-bottom: 0.75rem;
      span {
        font-size: 0.8rem;
        background: $blue;
        color: $white;
        padding: 0.5rem;
        border-radius: 0 12.5px;
      }
    }
  }
  .btn {
    margin-top: 1rem;
    background: $orange;
    color: $blue;
    font-weight: 600;
  }
}

.allPostsLink {
  .btn-large {
    background: $orange;
    color: $blue;
    font-weight: 600;
  }
}

.post {
  margin-top: 2rem;
  img {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    margin: 0 auto;
  }
  h1 {
    color: $blue;
  }
  .meta-category {
    color: $blue;
    font-weight: 600;
  }
}

.related-posts {
  .grid-item {
    display: inline;
    padding-right: 1.5rem;
    font-size: 1.4rem;
    a {
      color: $blue !important;
    }
  }
}

.blog-post-end {
  text-align: center;
}

.category-filters {
  h5 {
    padding-bottom: 0.5rem;
  }
  a {
    margin-bottom: 1rem;
    span {
      background: #142e5c;
      color: #fff;
      padding: 0.75rem 1rem;
      border-top-right-radius: 12px;
      border-bottom-left-radius: 12px;
    }
  }
}

p {
  strong {
    font-weight: 600;
  }
}

.category-trigger {
  color: $blue;
  font-weight: 600;
  width: 260px;
  background: $orange;
  &:hover {
    background: $orange;
  }
  &:focus {
    background: $orange;
  }
  @media screen and (max-width: $large-mobile) {
    width: 100%;
  }
}

.dropdown-content {
  border-radius: 0 12.5px;
  li {
    a {
      color: $blue;
      -webkit-transition: 0.3s;
      -o-transition: 0.3s;
      transition: 0.3s;
      &:hover {
        background: $blue;
        color: $orange;
      }
    }
  }
}

.post-main {
  .content {
    ul {
      li {
        margin-left: 2rem;
        list-style: initial;
        strong {
          font-weight: 600;
        }
      }
    }
    ol {
      list-style: initial;
    }
  }
}
