nav {
  max-width: 1920px;
  margin: 0 auto;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  z-index: 99;
  position: fixed;
  padding-top: 1rem;
  height: 80px;
  line-height: 80px;
  .brand-logo {
    margin-top: 6px;
    .white-logo {
      -webkit-transition: 0.5s;
      -o-transition: 0.5s;
      transition: 0.5s;
      -webkit-filter: drop-shadow(8px 8px 7px rgba(0, 0, 0, 0.7));
      filter: drop-shadow(8px 8px 7px rgba(0, 0, 0, 0.7));
    }
  }
  background: none;
  box-shadow: none;
  -webkit-box-shadow: none;
  ul {
    a {
      margin-top: -27px;
    }
  }
}

.sidenav {
  width: 100%;
  .side-nav-logo {
    margin: 8px 20px 0 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  li {
    a {
      margin-top: 0;
      color: $blue;
    }
  }
}
#nav-mobile {
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 600;
  height: 26px;
  margin-top: 0px;
  border-top-right-radius: 12px;
  border-bottom-left-radius: 12px;
  background: $white;
  li {
    .btn-large {
      -webkit-transition: 0.5s;
      -o-transition: 0.5s;
      transition: 0.5s;
      margin-top: -54px;
      color: $blue;
      border: none;
      background: $orange;
      border-top-right-radius: 12.5px;
      border-bottom-left-radius: 12.5px;
      &:hover {
        -webkit-transition: 0.5s;
        -o-transition: 0.5s;
        transition: 0.5s;
        background: $orange;
        color: $blue;
      }
    }
    a {
      -webkit-transition: 0.5s;
      -o-transition: 0.5s;
      transition: 0.5s;
      color: $blue;
      &:hover {
        -webkit-transition: 0.5s;
        -o-transition: 0.5s;
        transition: 0.5s;
        color: $orange;
        background: none;
      }
    }
  }
}
#nav-mobiletop {
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 600;
  height: 26px;
  margin-right: 10px;
  border-top-right-radius: 12.5px;
  border-top-left-radius: 12.5px;
  background: $white;
  margin-bottom: -7px;
  margin-right: 32px;
  li {
    a {
      -webkit-transition: 0.5s;
      -o-transition: 0.5s;
      transition: 0.5s;
      color: $blue;
      &:hover {
        -webkit-transition: 0.5s;
        -o-transition: 0.5s;
        transition: 0.5s;
        color: $orange;
        background: none;
      }
    }
  }
}
.sidenav-trigger {
  margin: -12px 18px !important;
  color: $white;
}
.close-side-nav {
  color: $blue;
  font-size: 2rem;
  &:hover {
    cursor: pointer;
  }
}
html.admin-bar body {
  margin-top: 32px;
}

.nav-scrolled {
  .sidenav-trigger {
    color: $blue;
  }
  nav {
    background: $white;
    color: $blue;
    padding-top: 0rem;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    -webkit-box-shadow: 0px 1px 0.5px 0.5px $blue;
    box-shadow: 0px 1px 0.5px 0.5px $blue;
  }
  #nav-mobile {
    margin-top: -8px;
    li {
      .btn-large {
        margin-top: -54px;
        color: $blue;
        border: none;
        background: $orange;
        height: 36px;
        line-height: 36px;
        &:hover {
          color: $blue;
          background: $orange;
        }
      }
      .menu-link {
        margin-top: -38px;
      }
      a {
        color: $blue;
        &:hover {
          color: $orange;
          background: none;
        }
      }
    }
  }
  #nav-mobiletop {
    margin-top: 5px;
    li {
      a {
        color: $blue;
        &:hover {
          color: $orange;
          background: none;
        }
      }
    }
  }
}

#slide-out {
  .side-nav-logo {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    .logo {
      margin: 2rem 0;
    }
  }
  .mobile-nav {
    li {
      padding-bottom: 2rem;
      a {
        padding: 0;
        font-size: 4rem;
        &:hover {
          background-color: none;
        }
      }
    }
  }
  .mobile-nav-right {
    li {
      padding: 0;
    }
  }
}
