.mainFooter {
  -webkit-clip-path: ellipse(85% 100% at center bottom);
  clip-path: ellipse(85% 100% at center bottom);
  background-image: url("../../dist/img/dots.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: 91%;
  background-position-y: -2vw;
  background-color: $slate;
  color: $white;
  @media screen and (max-width: $large-tablet) {
    -webkit-clip-path: ellipse(92% 100% at center bottom);
    clip-path: ellipse(92% 100% at center bottom);
  }
  @media screen and (max-width: $large-mobile) {
    background-size: 80%;
    background-position-y: 112vw;
    -webkit-clip-path: ellipse(170% 100% at center bottom);
    clip-path: ellipse(170% 100% at center bottom);
  }
  #footer-hero {
    padding-top: 4rem;
    min-height: 370px;
    h2 {
      padding-top: 2rem;
    }
  }
  .flyntComponent-footer {
    padding-bottom: 4rem;
    .footer-list-item {
      font-size: 1.05rem;
    }
    a {
      -webkit-transition: 0.5s;
      -o-transition: 0.5s;
      transition: 0.5s;
      color: $white;
      &:hover {
        color: $orange;
      }
    }
    .footer-list-item-header {
      border-bottom: 2px solid $white;
      padding-bottom: 4px;
      font-size: 1.5rem;
    }
    .social {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      li {
        display: inline;
        padding-right: 1rem;
        font-size: 1.5rem;
        @media screen and (max-width: $medium-tablet) {
          font-size: 3rem;
          padding-right: 3rem;
        }
        @media screen and (max-width: $large-mobile) {
          font-size: 2rem;
          padding: 0 1rem;
        }
        a {
          p {
            display: none;
          }
        }
      }
    }
  }
}
