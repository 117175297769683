.pagination {
  margin-bottom: 2rem;
  li {
    height: auto;
    a {
      color: $blue;
      line-height: 0;
    }
  }
  li.active {
    background-color: $blue;
  }
}
