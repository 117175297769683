.tabs {
  overflow-x: none;
  @media screen and (max-width: $large-mobile) {
    overflow-x: auto;
  }
  .indicator {
    display: none;
    background-color: $orange;
    height: 3px;
    bottom: 51px;
  }
  height: 60px;
  .tab {
    height: 100px;
    line-height: 108px;
    background: $blue;
    color: $white;
    font-weight: 600;
    a {
      margin-top: -26px;
      color: $orange;
      @media screen and (max-width: $large-tablet) {
        margin-top: 0;
      }
      @media screen and (max-width: $large-mobile) {
        margin-top: 0;
      }
      &:hover {
        color: $white;
        &:active {
          color: $white;
        }
      }
    }
  }
}

.tabs .tab a.active {
  color: $white;
}

.tabs .tab a:focus,
.tabs .tab a:focus.active {
  background-color: rgba(246, 178, 181, 0);
}
